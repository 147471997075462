/**
 * Composant de recherche Audio dourous & coran par props
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loader from '../Loader';
import { Link } from '@reach/router';

export default function SearchAudio(props) {
	const { theme } = props;

	const [query, setQuery] = useState('');
	const [data, setData] = useState(null);
	const [loader, setLoader] = useState(false);
	const [requestIsNull, setRequestIsNull] = useState(false);
	const [saveRequest, setSaveRequest] = useState(null);

	let queryList = [];
	const [queryListState, setQueryListState] = useState(queryList);

	// Get Data
	useEffect(() => {
		// Init Function
		const checkLocalStorageAge = (date) => {
			const today = Date.now();
			const timeDifference = today - date;

			const daysDifference = timeDifference / (1000 * 3600 * 24);
			if (daysDifference >= 1) {
				setLoader(true);
				localStorage.removeItem(
					`${theme === 'audios' ? 'liste_audio' : 'liste_audio_coran'}`
				);
				localStorage.removeItem(
					`${
						theme === 'audios'
							? 'liste_audio_storageDate'
							: 'liste_audio_storageDate_coran'
					}`
				);
				// console.log('start Get Audio')
				axios
					.get(
						`https://us-central1-dourousnet-3fcb0.cloudfunctions.net/getAudio?request=${
							theme === 'audios' ? 'dourous' : 'coran'
						}`
					)
					.then(function (res) {
						setData(res.data.listeAudio);
						localStorage.setItem(
							`${theme === 'audios' ? 'liste_audio' : 'liste_audio_coran'}`,
							JSON.stringify(res.data.listeAudio)
						);
						localStorage.setItem(
							`${
								theme === 'audios'
									? 'liste_audio_storageDate'
									: 'liste_audio_storageDate_coran'
							}`,
							Date.now()
						);
						setLoader(false);
					})
					.catch(function (err) {
						console.log('error get Dourous', err);
						setLoader(false);
					});
			} else {
				setData(
					JSON.parse(
						localStorage.getItem(
							`${theme === 'audios' ? 'liste_audio' : 'liste_audio_coran'}`
						)
					)
				);
				setLoader(false);
			}
		};

		// Si on détècte une liste dans le local storage
		if (
			JSON.parse(
				localStorage.getItem(`${theme === 'audios' ? 'liste_audio' : 'liste_audio_coran'}`)
			)
		) {
			if (
				localStorage.getItem(
					`${
						theme === 'audios'
							? 'liste_audio_storageDate'
							: 'liste_audio_storageDate_coran'
					}`
				)
			) {
				// si la date existe
				const date = localStorage.getItem(
					`${
						theme === 'audios'
							? 'liste_audio_storageDate'
							: 'liste_audio_storageDate_coran'
					}`
				);
				checkLocalStorageAge(date);
			}
		} else {
			// console.log('start Get Audio')
			setLoader(true);
			axios
				.get(
					`https://us-central1-dourousnet-3fcb0.cloudfunctions.net/getAudio?request=dourous`
				)
				.then(function (res) {
					setData(res.data.listeAudio);
					localStorage.setItem(
						`${theme === 'audios' ? 'liste_audio' : 'liste_audio_coran'}`,
						JSON.stringify(res.data.listeAudio)
					);
					localStorage.setItem(
						`${
							theme === 'audios'
								? 'liste_audio_storageDate'
								: 'liste_audio_storageDate_coran'
						}`,
						Date.now()
					);
					setLoader(false);
					console.log('axios =>', true);
				})
				.catch(function (err) {
					console.log('error get Dourous', err);
					setLoader(false);
				});
		}
	}, [theme]);

	let newList;

	useEffect(() => {
		const nString = query.length;
		if (query === '' || nString < 2) {
			setQueryListState([]); // réinitialise
			if (newList) {
				newList.length = [];
				queryList.length = [];
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	const handleSubmit = (e) => {
		e.preventDefault();

		if (saveRequest === query) {
			return;
		} else {
			if (queryListState.length > 0 && query === '') {
				return;
			} else {
				if (query === '' || query === ' ') {
					setQueryListState([]); // réinitialise
				} else {
					if (data) {
						data.forEach(function (item) {
							const index = item.title.search(new RegExp(query, 'i'));

							if (index !== -1) {
								queryList.push(item);
								if (requestIsNull) {
									setRequestIsNull(false);
								}
							} else {
								return;
							}

							if (queryList.length > 0) {
								newList = queryListState.concat(queryList);
								setQueryListState(newList);
							}
						});

						if (queryList.length <= 0) {
							setRequestIsNull(true);
						}
					}
				}
			}

			setSaveRequest(query);
		}
	};

	let input;
	if (!loader) {
		input = (
			<>
				<input
					type="search"
					value={query}
					id="search"
					onChange={(e) => setQuery(e.target.value)}
					placeholder="Recherche audio"
					className={`w-full rounded-l-full px-10 h-16 border border-gray-200 text-gray-400`}
				/>
				<button className="bg-secondary hover:bg-primary text-white flex items-center justify-center rounded-r-full px-10 h-16 border border-secondary hover:border-primary animate">
					<svg
						className="h-5 w-5"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						strokeWidth="2"
						stroke="currentColor"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<path stroke="none" d="M0 0h24v24H0z" /> <circle cx="10" cy="10" r="7" />{' '}
						<line x1="21" y1="21" x2="15" y2="15" />
					</svg>
				</button>
			</>
		);
	} else {
		input = (
			<div className="w-full flex items-center justify-center px-10 h-16">
				<Loader />
			</div>
		);
	}

	let resultQuery;

	if (query) {
		resultQuery = (
			<div className="flex items-center justify-center w-full">
				<div className="w-full">
					{queryListState.length > 0 ? (
						<div className="flex justify-end mb-5">
							<span>
								<span className="mr-1 font-bold text-lg text-secondary">
									{queryListState.length}
								</span>
								résultat(s)
							</span>
						</div>
					) : null}

					<div className="grid md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-10">
						{queryListState.length > 0 && !requestIsNull ? (
							queryListState.map((item, index) => (
								<Link
									to={`/${
										theme === 'audios' ? 'audios' : 'coran'
									}/?cat=${item.theme.replace(
										/ /g,
										'+'
									)}&&title=${item.title.replace(/ /g, '+')}`}
									key={index}
									className="border border-gray-200 px-10 pb-10 pt-0 hover:bg-primary hover:text-white animate"
								>
									<div className="w-full flex items-center justify-center">
										<svg
											className="bg-white p-3 rounded-full h-14 w-14 text-secondary -mt-7 mb-10"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											strokeWidth="2"
											stroke="currentColor"
											fill="none"
											strokeLinecap="round"
											strokeLinejoin="round"
										>
											<path stroke="none" d="M0 0h24v24H0z" />{' '}
											<path d="M15 8a5 5 0 0 1 0 8" />{' '}
											<path d="M17.7 5a9 9 0 0 1 0 14" />{' '}
											<path d="M6 15 h-2a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h2l3.5 -4.5a.8 .8 0 0 1 1.5 .5v14a.8 .8 0 0 1 -1.5 .5l-3.5 -4.5" />
										</svg>
									</div>
									<div className="text-center">{item.title}</div>
								</Link>
							))
						) : requestIsNull ? (
							<div className="md:col-span-2 lg:col-span-4 w-full flex items-center justify-center">
								<div className="flex flex-col items-center justify-center*">
									<svg
										className="h-8 w-8 text-secondary mb-3"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										strokeWidth="2"
										stroke="currentColor"
										fill="none"
										strokeLinecap="round"
										strokeLinejoin="round"
									>
										<path stroke="none" d="M0 0h24v24H0z" />{' '}
										<circle cx="12" cy="12" r="9" />{' '}
										<line x1="5.7" y1="5.7" x2="18.3" y2="18.3" />
									</svg>
									<div className="text-xs">
										Nous n'avons pas trouvé de résultats pour cette recherche
									</div>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			<form onSubmit={handleSubmit} className="w-full flex items-center justify-center">
				{input}
			</form>

			{resultQuery}
		</>
	);
}
