/**
 * Page Recherche
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React, { useState } from 'react';
import Container from '../components/body/Container';
import Layout from '../components/body/layout';
import Seo from '../components/seo';
import SearchAudio from '../components/functional/searchComponent/SearchAudio';

export default function Search() {
	const [select, setSelect] = useState('audios');

	return (
		<Layout title="Recherche">
			<Seo title="Recherche sur le site : Nader Abou Anas" />
			<Container className="">
				{/* Menu */}
				<div className="w-full flex items-center justify-center mt-10 mb-10">
					{/* <div className="mx-2">
						<button
							onClick={() => setSelect('videos')}
							className={`${
								select === 'videos'
									? 'border-secondary text-white bg-secondary'
									: 'border-gray-300 text-primary'
							} border w-full h-full py-2 md:py-3 rounded-full hover:border-primary hover:bg-primary hover:text-white text-xxs md:text-xs animate px-5`}
						>
							Vidéos
						</button>
					</div> */}

					<div className="mx-2">
						<button
							onClick={() => setSelect('audios')}
							className={`${
								select === 'audios'
									? 'border-secondary text-white bg-secondary'
									: 'border-gray-300 text-primary'
							} border w-full h-full py-2 md:py-3 rounded-full hover:border-primary hover:bg-primary hover:text-white text-xxs md:text-xs animate px-5`}
						>
							Audios
						</button>
					</div>

					<div className="mx-2">
						<button
							onClick={() => setSelect('coran')}
							className={`${
								select === 'coran'
									? 'border-secondary text-white bg-secondary'
									: 'border-gray-300 text-primary'
							} border w-full h-full py-2 md:py-3 rounded-full hover:border-primary hover:bg-primary hover:text-white text-xxs md:text-xs animate px-5`}
						>
							Coran
						</button>
					</div>
				</div>

				{/* Search Components */}
				<div className="flex flex-col items-center justify-center mt-5 mb-20">
					{/* {select === 'videos' ? <SearchVideos /> : null} */}
					{select === 'audios' ? <SearchAudio theme={'audios'} /> : null}
					{select === 'coran' ? <SearchAudio theme={'coran'} /> : null}
				</div>
			</Container>
		</Layout>
	);
}
